
import React, { useState, useEffect, useReducer, useCallback } from "react";

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';

import api from "../../services/api";
import Swal from 'sweetalert2'

import toastError from "../../errors/toastError";
import Grid from '@mui/material/Grid';

import {
  ViewState,
} from '@devexpress/dx-react-scheduler';

import {
  Scheduler,
  WeekView,
  DayView,
  Appointments,
  Toolbar,
  DateNavigator,
  ViewSwitcher,
  AppointmentForm,
  AppointmentTooltip,
  TodayButton,
} from '@devexpress/dx-react-scheduler-material-ui';

import Kanboard from "../../components/Kanboard";


const PREFIX = 'Demo';

const classes = {
  toolbarRoot: `${PREFIX}-toolbarRoot`,
  progress: `${PREFIX}-progress`,
};

const StyledDiv = styled('div')({
  [`&.${classes.toolbarRoot}`]: {
    position: 'relative',
  },
});

const StyledLinearProgress = styled(LinearProgress)(() => ({
  [`&.${classes.progress}`]: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
  },
}));



const pageNumber=1;
const searchParam = "";


const getBookings = async (setData, setLoading) => {
setLoading(true);
try {
    const { data } = await api.get("/agenda/", {params: { searchParam, pageNumber }});
    setTimeout(() => {
        setData(data);
        setLoading(false);
        }, 600);
} catch (err) {
    toastError(err);
    }
};

const ToolbarWithLoading = (
  ({ children, ...restProps }) => (
    <StyledDiv className={classes.toolbarRoot}>
      <Toolbar.Root {...restProps}>
        {children}
      </Toolbar.Root>
      <StyledLinearProgress className={classes.progress} />
    </StyledDiv>
  )
);

const usaTime = date => new Date(date).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });

const cbaTime = date => new Date(date).toLocaleString('pt-BR', { timeZone: 'America/Cuiaba' });

const mapAppointmentData = appointment => ({
  id: appointment.id,
  startDate: usaTime(appointment.start.dateTime),
  endDate: usaTime(appointment.end.dateTime),
  title: appointment.summary,
});

const mapBookingData = booking => ({
                                            id          : booking.bookingId,
                                            title       : `${booking.bookingTypeName} para ${booking.username}\n${booking.bookingName} `,
                                            startDate   : new Date(booking.bookingTzidFrom),
                                            endDate     : new Date(booking.bookingTzidTo),
                                            notes       : booking.description,
                                            extInfo     : {   service: booking.positionName, 
                                                            task: booking.bookingName,
                                                            attendant: booking.bookingTypeName,
                                                            contactName: booking.name,
                                                            contactCPF: booking.contactCpf,
                                                            contactDOB: booking.contactDob,
                                                            contactNumber: booking.contactNumber, 
                                                            profilePic: booking.profilePicUrl,
                                                            distance: booking.distance,
                                                            description: booking.description,
                                                            username:booking.username
                                                        },
                                        }
  );

const initialState = {
  data: [],
  loading: false,
  currentDate: new Date(),
  currentViewName: 'Day',
};

const reducer = (state, action) => {

    console.log(action.type, action.payload)

    switch (action.type) {
      case 'setLoading':
        return { ...state, loading: action.payload };
      case 'setData':
        return { ...state, data: action.payload.map(mapAppointmentData) };
      case 'Load_Bookings':
        return { ...state, data: action.payload.map(mapBookingData) };
      case 'setCurrentViewName':
        return { ...state, currentViewName: action.payload };
      case 'setCurrentDate':
        return { ...state, currentDate: action.payload };
      default:
        return state;
    }
  };
  
const AdvScheduler = () => {

  const [state, dispatch] = useReducer(reducer, initialState);

  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [hasMore, setHasMore] = useState(false);



  const {data, loading, currentViewName, currentDate} = state;
  
  const setCurrentViewName = useCallback(nextViewName => dispatch({type: 'setCurrentViewName', payload: nextViewName}), [dispatch]);
  const setBookings = useCallback(nextData => dispatch({ type: 'Load_Bookings', payload: nextData}), [dispatch]);
  const setCurrentDate = useCallback(nextDate => dispatch({ type: 'setCurrentDate', payload: nextDate }), [dispatch]);
  const setLoading = useCallback(nextLoading => dispatch({ type: 'setLoading', payload: nextLoading }), [dispatch]);

  useEffect(() => {
    getBookings(setBookings, setLoading);
  }, [setBookings, currentViewName, currentDate]);




      function nodeClick(e) {
        console.log(e.data)

        Swal.fire({
          title: 'Informação de Agendamento',   
          html: `Tarefa : ${e.data.id} de ${e.data.extInfo.username}<br />
                <img src='${e.data.extInfo.profilePic}' style='width:150px; border-radius:5px'>
                <br/><span style='font-weight:600;'>Contato : </span>${e.data.extInfo.contactName} - ${e.data.extInfo.contactNumber}
                <br/><span style='font-weight:600;'>Tarefa : </span>${e.data.title}
                <br/><span style='font-weight:600;'>Localização : </span>${e.data.extInfo.service}
                <br><span style='font-weight:600;'>Descrição : </span>${e.data.extInfo.description}`,
          confirmButtonText: 'Fechar'
        })
      }
    

       const Appointment = ({ children, style, ...restProps }) => (
        <Appointments.Appointment
          {...restProps}
          style={{
            ...style,
            backgroundColor: "#fc5c6f",
            borderRadius: "8px"
          }}
          //onClick={handleExtraInfo()}
          onClick={nodeClick}
        >
          {children}
        </Appointments.Appointment>
      
      );

  return (
    <>

    <Grid container alignItems="center">
        <Grid item xs={6} >
               
            <Scheduler
                data={data}
                height={880}
            >
                <ViewState
                    currentDate={currentDate}
                    currentViewName={currentViewName}
                    onCurrentViewNameChange={setCurrentViewName}
                    onCurrentDateChange={setCurrentDate}
                />
                <DayView
                    startDayHour={7.5}
                    endDayHour={17.5}
                />
                <WeekView
                startDayHour={7.5}
                endDayHour={17.5}
                />
                <Appointments />
                <Toolbar
                    {...loading ? { rootComponent: ToolbarWithLoading } : null}
                />
                <DateNavigator />
                <TodayButton />
                <ViewSwitcher />

                <Appointments 
                    appointmentComponent={Appointment} 
                />
                
            </Scheduler>
               
        </Grid>
        <Grid item xs={6}>
               
                    <Kanboard/>
               
        </Grid>
    </Grid>

    </>

  );
};


export default AdvScheduler