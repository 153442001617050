
import React, { useState, useEffect, useReducer, useContext, useRef } from "react";
import openSocket from "../../services/socket-io";

import { List,
	makeStyles,
	Paper,
} from "@material-ui/core";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";

import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { ContactSupportOutlined } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
	ticketsListWrapper: {
		position: "relative",
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflow: "hidden",
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	},

	ticketsList: {
		flex: 1,
		overflowY: "scroll",
		...theme.scrollbarStyles,
		borderTop: "2px solid rgba(0, 0, 0, 0.12)",
	},

	ticketsListHeader: {
		color: "rgb(67, 83, 105)",
		zIndex: 2,
		backgroundColor: "white",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},

	ticketsCount: {
		fontWeight: "normal",
		color: "rgb(104, 121, 146)",
		marginLeft: "8px",
		fontSize: "14px",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
}));

const reducer = (state, action) => {

	if (action.type === "LOAD_TICKETS") {
		const newTickets = action.payload;

		newTickets.forEach(ticket => {
			const ticketIndex = state.findIndex(t => t.id === ticket.id);
			if (ticketIndex !== -1) {
				state[ticketIndex] = ticket;
				if (ticket.unreadMessages > 0) {
					state.unshift(state.splice(ticketIndex, 1)[0]);
				}
			} else {
				state.push(ticket);
			}
		});

		return [...state];
	}

	if (action.type === "RELOAD_TICKETS") {
		
		const filteredTickets = action.payload;
		state.length = 0;

		filteredTickets.forEach(ticket => {
			state.push(ticket);
		});

		return [...state];
	}

	if (action.type === "RESET_UNREAD") {
		const ticketId = action.payload;

		const ticketIndex = state.findIndex(t => t.id === ticketId);
		if (ticketIndex !== -1) {
			state[ticketIndex].unreadMessages = 0;
		}

		return [...state];
	}

	if (action.type === "UPDATE_TICKET") {
		const ticket = action.payload;

		const ticketIndex = state.findIndex(t => t.id === ticket.id);
		if (ticketIndex !== -1) {
			state[ticketIndex] = ticket;
		} else {
			state.unshift(ticket);
		}

		return [...state];
	}

	if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
		/*
		const ticket = action.payload;
		console.log("action.type === UPDATE_TICKET_UNREAD_MESSAGES 1", ticket)
		const ticketIndex = state.findIndex(t => t.id === ticket.id);
		console.log("action.type === UPDATE_TICKET_UNREAD_MESSAGES 2", ticketIndex)
		console.log("action.type === UPDATE_TICKET_UNREAD_MESSAGES 3",state)

		if (ticketIndex !== -1) {
			state[ticketIndex] = ticket;
			state.unshift(state.splice(ticketIndex, 1)[0]);
		} else {
			state.unshift(ticket);
		}
		*/

		return [...state];
	}

	if (action.type === "UPDATE_TICKET_CONTACT") {
		const contact = action.payload;
		const ticketIndex = state.findIndex(t => t.contactId === contact.id);
		if (ticketIndex !== -1) {
			state[ticketIndex].contact = contact;
		}
		return [...state];
	}

	if (action.type === "DELETE_TICKET") {
		const ticketId = action.payload;
		const ticketIndex = state.findIndex(t => t.id === ticketId);
		if (ticketIndex !== -1) {
			state.splice(ticketIndex, 1);
		}

		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

	const TicketsList = ({ status, searchParam, showAll, tags, users, selectedQueueIds, updateCount, style }) => {
		
	//console.log("TicketsList tags", tags)
	//console.log("TicketsList users", users)

	const classes = useStyles();
	const [pageNumber, setPageNumber] = useState(1);
	const [listUsers, setlistUsers] = useState(users);

	const [ticketsList, dispatch] = useReducer(reducer, []);
	const { user } = useContext(AuthContext);
	const { profile, queues } = user;
	//console.log("Tickets List User", user)

	useEffect(() => {
		dispatch({ type: "RESET" });
		setPageNumber(1);
	}, [status, searchParam, dispatch, showAll, tags, selectedQueueIds]);

	const { tickets, hasMore, loading } = useTickets({
		pageNumber,
		searchParam,
		tags: JSON.stringify(tags),
		users:JSON.stringify(users),
		status,
		showAll,
		queueIds: JSON.stringify(selectedQueueIds),
	});



	useEffect(() => {
		const queueIds = queues.map((q) => q.id);
		const filteredTickets = tickets.filter((t) => queueIds.indexOf(t.queueId) > -1);

		if (profile === "user") {
			dispatch({ type: "LOAD_TICKETS", payload: filteredTickets });
		} else {
			dispatch({ type: "LOAD_TICKETS", payload: tickets });
		}
	}, [tickets, status, searchParam, tags, users, queues, profile]);

	useEffect(() => {

		const queueIds = queues.map((q) => q.id);
		const filteredTickets = tickets.filter((t) => queueIds.indexOf(t.queueId) > -1);

		if ((tags || users) && tickets){
			console.log("RELOAD_TICKETS 1", tags, users,tickets)	

			if (profile === "user") {
				dispatch({ type: "RELOAD_TICKETS", payload: filteredTickets });
			} else {
				dispatch({ type: "RELOAD_TICKETS", payload: tickets });
			}
		}
	}, [tickets, tags, users, queues, profile]);
	

	useEffect(() => {
		const socket = openSocket();

		const shouldUpdateTicket = ticket =>
			(!ticket.userId || ticket.userId === user?.id || showAll) &&
			(!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1);

		const notBelongsToUserQueues = ticket => 
			ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

		const userCanSee = ticket => {
			if(!ticket?.queueId && profile != "user"){
				return true;
			}
			else{
				return false;
			}
		}

		socket.on("connect", () => {
			if (status) {
				socket.emit("joinTickets", status);
			} else {
				socket.emit("joinNotification");
			}
		});

		socket.on("ticket", data => {

			
			if (data.action === "reload") {
				dispatch({
					type: "LOAD_TICKETS",
					payload: tickets,
				});
			}

			if (data.action === "updateUnread") {
				dispatch({
					type: "RESET_UNREAD",
					payload: data.ticketId,
				});
			}
			
			if (!userCanSee(data.ticket)) {
				console.log("userCanSee 7 - hide ticket with id:", data.ticket.id)
				dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
			}

			if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
				dispatch({
					type: "UPDATE_TICKET",
					payload: data.ticket,
				});
			}

			if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
				dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
			}

			if (data.action === "delete") {
				dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
			}
		});

		socket.on("appMessage", data => {

			if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
				dispatch({
					type: "UPDATE_TICKET_UNREAD_MESSAGES",
					payload: data.ticket,
				});
			}
		});

		socket.on("contact", data => {
			console.log("socket.on(contact)", data )
			if (data.action === "update") {
				dispatch({
					type: "UPDATE_TICKET_CONTACT",
					payload: data.contact,
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [status, showAll, user, selectedQueueIds, tags]);

	useEffect(() => {
		if (typeof updateCount === "function") {
		updateCount(ticketsList.length);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ticketsList]);

	const loadMore = () => {
		setPageNumber(prevState => prevState + 1);
	};

	const handleScroll = e => {
		if (!hasMore || loading) return;

		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

		if (scrollHeight - (scrollTop + 100) < clientHeight) {
			loadMore();
		}
	};

	return (
    <Paper className={classes.ticketsListWrapper} style={style}>
			<Paper
				square
				name="closed"
				elevation={0}
				className={classes.ticketsList}
				onScroll={handleScroll}
			>
				<List style={{ paddingTop: 0 }}>
					{ticketsList.length === 0 && !loading ? (
						<div className={classes.noTicketsDiv}>
							<span className={classes.noTicketsTitle}>
								{i18n.t("ticketsList.noTickets.title")}
							</span>
							<p className={classes.noTicketsText}>
								{i18n.t("ticketsList.noTickets.message")}
							</p>
						</div>
					) : (
						<>
							{ticketsList.map(ticket => (
								<Card style={{marginTop:"5px", marginBottom:"5px", borderRadius:"5px"}} variant="outlined">
									<TicketListItem ticket={ticket} key={ticket.id} />
								</Card>
							))}
						</>
					)}
					{loading && <TicketsListSkeleton />}
				</List>
			</Paper>
    </Paper>
	);
};

export default TicketsList;

