import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoggedInLayout from "../layout";
import Bots from "../pages/Bots";
import Dashboard from "../pages/Dashboard/";
import Tickets from "../pages/Tickets/";
import Signup from "../pages/Signup/";
import ScheduledMessages from "../pages/ScheduledMessages";
import Login from "../pages/Login/";
import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import QuickAnswers from "../pages/QuickAnswers/";
import Queues from "../pages/Queues/";
import Campaign from "../pages/Campaign";
import Catalog from "../pages/Catalog";
import Categories from "../pages/Categories";
import NLPConfig from "../pages/NLPConfig";

import Super from "../pages/Super";
import Support from "../pages/Support"


import Broadcasts from "../pages/Broadcasts"
import Tags from "../pages/Tags";
import BizFlow from "../pages/BizFlow"
import Chat from "../pages/Chat";
import Kanban from "../pages/Kanban";

import Groups from "../pages/Groups";

import MessagingAPI from "../pages/MessagingAPI/";

import AdvScheduler from "../pages/AdvScheduler"

import Announcements from "../pages/Annoucements";

import UpgradeInfo from "../pages/UpgradeInfo";
import WaitPanel from "../pages/WaitPanel"
import ClientMap from "../pages/ClientMap";
import GroupJoin  from "../pages/GroupJoin"

import LeafletMap from "../pages/LeafletMap"
import AlexaParrot from "../pages/AlexaParrot"

import NewTicket from "../pages/NewTicket"

import Tableaux from "../pages/Tableaux"

import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";





const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/groupjoin" component={GroupJoin} />
          
          <WhatsAppsProvider>
            <LoggedInLayout>
              <Route exact path="/" component={Tickets} isPrivate />
              <Route exact path="/dashboard" component={Dashboard} isPrivate />
              <Route exact path="/tickets/:ticketId?" component={Tickets} isPrivate />
              <Route exact path="/connections" component={Connections} isPrivate />
              <Route exact path="/contacts" component={Contacts} isPrivate />
              <Route exact path="/users" component={Users} isPrivate />
              <Route exact path="/catalog" component={Catalog} isPrivate />        
              <Route exact path="/quickAnswers" component={QuickAnswers} isPrivate />
              <Route exact path="/scheduledMessages" component={ScheduledMessages} isPrivate />
              <Route exact path="/Bots" component={Bots} isPrivate />
              <Route exact path="/Settings" component={Settings} isPrivate />
              <Route exact path="/Queues" component={Queues} isPrivate />
              <Route exact path="/Campaign" component={Campaign} isPrivate />
              <Route exact path="/Categories" component={Categories} isPrivate />
              <Route exact path="/NLPConfig" component={NLPConfig} isPrivate />
              <Route exatc path="/Support" component={Support} isPrivate/>
              <Route exact path="/Super" component={Super} isPrivate />
              <Route exact path="/advscheduler" component={AdvScheduler} isPrivate />
              <Route exact path="/Broadcasts" component={Broadcasts} isPrivate />
              <Route exact path="/Tags" component={Tags} isPrivate />
              <Route exact path="/BizFlow" component={BizFlow} isPrivate />
              <Route exact path="/Kanban" component={Kanban} isPrivate />
              <Route exact path="/chats/:id?" component={Chat} isPrivate />
              <Route exact path="/Groups" component={Groups} isPrivate />
              <Route exact path="/MessagingAPI" component={MessagingAPI} isPrivate />
              <Route exact path="/announcements" component={Announcements} isPrivate />
              <Route exact path="/upgradeinfo" component={UpgradeInfo} isPrivate/>
              <Route exact path="/waitpanel" component={WaitPanel} isPrivate/>
              <Route exact path="/ClientMap" component={ClientMap} isPrivate/>
              <Route exact path="/LeafletMap" component={LeafletMap} isPrivate/>
              <Route exact path="/AlexaParrot" component={AlexaParrot} isPrivate/>
              <Route exact path="/newticket" component={NewTicket} isPrivate/>
              <Route exact path="/tableaux" component={Tableaux} isPrivate/>
          
              
              
            </LoggedInLayout>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
