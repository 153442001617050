import React, { useState, useEffect, useContext } from "react";
import PierChart from './graficos/PierChart';
import toastError from "../../errors/toastError";
import {blue, purple, red, green, teal, orange, amber} from '@material-ui/core/colors';


import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"

import {makeStyles,	TextField,	Typography, Box, Grid} from "@material-ui/core";
import './assets/css/app.css'
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { Formik, Form, Field } from "formik";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import ExportToExcel from "../../components/xlsBulkExport"
import { Can } from "../../components/Can"
import { AuthContext } from "../../context/Auth/AuthContext";

import Chart from "./Chart"

import AgentesTotais from './datatables/AgentesTotais';
import FilasTotais from './datatables/FilasTotais';
import TagsTotais from "./datatables/TagsTotais"
import Satisfaction from "./Satisfaction"
import UserStats from "./UserStats"
import SalesFunnel from "./SalesFunnel"
import AgentesDesempenhoTable from './datatables/AgentesDesempenhoTable';
import PierLabels from "./PierLabels"



const useStyles = makeStyles(theme => ({
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
          marginRight: theme.spacing(1),
        },
      },
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 240,
	},
	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 120,
	},
	customFixedHeightPaperLg: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "100%",
	},
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
      },
      green1: {
            color: green[500]
        },
      red1: {
            color: red[500]
        },
    
      blue1: {
            color: blue[500]
        },
      teal1: {
            color: teal[500]
        },
      orange1: {
            color: orange[500]
        },
      purple1: {
            color: purple[500]
        },
      amber1: {
            color: amber[500]
        },
      avatar:{
        width: "60px",
        height: "60px",
        borderRadius:"10%",
        marginRight:"10px",
        marginTop:"20px"
    },
    contentWrapper: {
    height: "100%",
    overflowY: "hidden",
    display: "flex",
    flexDirection: "column",
  },
}))


const Tableaux = () => {
    const classes = useStyles()
    const cbaTime = date => new Date(date).toLocaleString('pt-BR', { timeZone: 'America/Cuiaba' });
    const { user } = useContext(AuthContext);
    

    const initialState = {
        dateFrom: formatDate(new Date(new Date().setHours(new Date().getHours() - 23))),
        dateTo: formatDate(new Date(new Date().setHours(new Date().getHours() + 1))),
    }

	const [data, setData] = useState([])
  	const fileName = "BulkDataExport"; // here enter filename for your excel file


    const [inputs, setInputs] = useState(initialState);
    const [ticketStatus, setTicketStatus] = useState([]);

    const [firstAnswerMax,setFirstAnswerMax] = useState("00:00:00");
    const [firstAnswerAvg,setFirstAnswerAvg] = useState("00:00:00");
    const [closureMax,setClosureMax] = useState("00:00:00");
    const [closureAvg,setClosureAvg] = useState("00:00:00");

    const [newContacts,setNewContacts] = useState("0");
    const [newTickets,setNewTickets] = useState("0");

    const [userTotals,setUserTotals] = useState([]);
    const [queueTotals,setQueueTotals] = useState([]);
    const [tagTotals,setTagTotals] = useState([]);
    const [openByAge,setOpenByAge] = useState({"status": "open", "old07": "0","old14": "0", "older": "0"});

    const [pendingByAge,setPendingByAge] = useState({"status": "pending", "old07": "0", "old14": "0", "older": "0"});
 
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
          const fetchData = async () => {
                filtraDashboard ()
          };
          fetchData();
        }, 1500);
        return () => clearTimeout(delayDebounceFn);
      }, []);

    function formatDate(date) {
        
        const year = date.getFullYear();
        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let hour = date.getHours().toString();
        hour = hour.length > 1 ? hour : '0' + hour;
        let minute = date.getMinutes().toString();
        minute = minute.length > 1 ? minute : '0' + minute;

        return year + '-' + month + '-' + day + 'T' + hour + ':' + minute;
    }


    const loadTicketStatus = async () => {
            console.log("inputs", inputs)
            let payload = {
                "dhi": inputs.dateFrom.replace("T"," "),
                "dhf": inputs.dateTo.replace("T"," "),
            }
            
            try {
                console.log ("payload", payload)
                const { data } = await api.post("/ws/statuscount", payload);
                console.log("/ws/statuscount", data)
                setTicketStatus(data)
            } catch (err) {
                toastError(err);
            }

    }

    const loadFirstAnswerMax = async () => {
        
        let payload = {
            "dhi": inputs.dateFrom.replace("T"," "),
            "dhf": inputs.dateTo.replace("T"," "),
        }
      
        try {
            console.log ("payload", payload)
            const { data } = await api.post("/ws/firstanswermax", payload);
            console.log("/ws/firstanswermax", data)
            setFirstAnswerMax(data)
        } catch (err) {
            toastError(err);
        }

    }

    const loadFirstAnswerAvg = async () => {
        console.log("inputs", inputs)
        let payload = {
            "dhi": inputs.dateFrom.replace("T"," "),
            "dhf": inputs.dateTo.replace("T"," "),
        }
        
        try {
            console.log ("payload", payload)
            const { data } = await api.post("/ws/firstansweravg", payload);
            console.log("/ws/firstansweravg", data)
            setFirstAnswerAvg(data)
        } catch (err) {
            toastError(err);
        }

    }

    const loadClosureMax = async () => {
        console.log("inputs", inputs)
        let payload = {
            "dhi": inputs.dateFrom.replace("T"," "),
            "dhf": inputs.dateTo.replace("T"," "),
        }
        
        try {
            console.log ("payload", payload)
            const { data } = await api.post("/ws/ticketclosuremax", payload);
            console.log("/ws/ticketclosuremax", data)
            setClosureMax(data)
        } catch (err) {
            toastError(err);
        }

    }

    const loadClosureAvg = async () => {
        console.log("inputs", inputs)
        let payload = {
            "dhi": inputs.dateFrom.replace("T"," "),
            "dhf": inputs.dateTo.replace("T"," "),
        }
        
        try {
            console.log ("payload", payload)
            const { data } = await api.post("/ws/ticketclosureavg", payload);
            console.log("/ws/ticketclosureavg", data)
            setClosureAvg(data)
        } catch (err) {
            toastError(err);
        }

    }

    const loadNewContacts = async () => {
        console.log("inputs", inputs)
        let payload = {
            "dhi": inputs.dateFrom.replace("T"," "),
            "dhf": inputs.dateTo.replace("T"," "),
        }
        
        try {
            const { data } = await api.post("/ws/countnewcontacts", payload);
            console.log("/ws/countnewtickets", data)
            setNewContacts(data)
        } catch (err) {
            toastError(err);
        }

    }

    const loadNewTickets = async () => {
       
        let payload = {
            "dhi": inputs.dateFrom.replace("T"," "),
            "dhf": inputs.dateTo.replace("T"," "),
        }
        
        try {
            const { data } = await api.post("/ws/countnewtickets", payload);
            console.log("/ws/countnewtickets", data)
            setNewTickets(data)
        } catch (err) {
            toastError(err);
        }

    }

    const loadUserTotals = async () => {
       
        let payload = {
            "dhi": inputs.dateFrom.replace("T"," "),
            "dhf": inputs.dateTo.replace("T"," "),
        }
        
        try {
            const { data } = await api.post("/ws/usertotals", payload);
            console.log("/ws/usertotals", data)
            setUserTotals(data)
        } catch (err) {
            toastError(err);
        }

    }

    const loadQueueTotals = async () => {
       
        let payload = {
            "dhi": inputs.dateFrom.replace("T"," "),
            "dhf": inputs.dateTo.replace("T"," "),
        }
        
        try {
            const { data } = await api.post("/ws/queuetotals", payload);
            console.log("/ws/queuetotals", data)
            setQueueTotals(data)
        } catch (err) {
            toastError(err);
        }

    }

        
    const loadTagTotals = async () => {
       
        let payload = {
            "dhi": inputs.dateFrom.replace("T"," "),
            "dhf": inputs.dateTo.replace("T"," "),
        }
        
        try {
            const { data } = await api.post("/ws/tagtotals", payload);
            console.log("/ws/tagtotals", data)
            setTagTotals(data)
        } catch (err) {
            toastError(err);
        }

    }

    const loadOpenByAge = async () => {
              
        try {
            const { data } = await api.get("/ws/opencountbyage");
            console.log("/ws/opencountbyage", data)
            setOpenByAge(data)
        } catch (err) {
            toastError(err);
        }

    }

    const loadPendingByAge = async () => {
              
        try {
            const { data } = await api.get("/ws/pendingcountbyage");
            console.log("/ws/pendingcountbyage", data)
            setPendingByAge(data)
        } catch (err) {
            toastError(err);
        }

    }






    const filtraDashboard = async () => {
        
            loadTicketStatus()

            loadFirstAnswerMax()
            loadFirstAnswerAvg()
            loadClosureMax()
            loadClosureAvg()

            loadNewContacts()
            loadNewTickets()

            loadQueueTotals()
            loadUserTotals()
            loadTagTotals()

            loadOpenByAge()
            loadPendingByAge()
           
    }

    const handleDateFChange = async (e) => {
        try { 
            const newInputs = {...inputs, dateFrom: e.target.value};
            setInputs(newInputs);    
        } catch (err) {
            toastError(err);
        }
    };

    const handleDateTChange = async (e) => {
        try {
            const newInputs = {...inputs, dateTo: e.target.value};
            setInputs(newInputs);
        } catch (err) {
            toastError(err);
        }
    };



       	return (
                <>
                   
                    <Paper style={{ padding:"20px"}}>
                        <Typography paragraph>
                        <Title style={{marginTop:10, marginBottom:10}}>Indicadores de Desempenho</Title>
                        </Typography>
                    </Paper>
                    <Paper>
                        <>
                            <Formik
                                    initialValues={inputs}
                                    enableReinitialize={true}
                                    onSubmit={(values, actions, e) => {
                                        setTimeout(() => {
                                            //handleSaveSchedule(values);
                                            //actions.setSubmitting(false)
                                        }, 400);
                                    }}
                                >
                                    {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                                        <Form>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="card card-border-color card-border-color-primary">
                                                                <div className="card-body">
                                                                    <div className="form-group row">
                                                                        
                                                                                <div className="col-md-2">
                                                                                    
                                                                                        <Field
                                                                                            as={TextField}
                                                                                            label="Data e Hora Desde"
                                                                                            type="datetime-local"
                                                                                            ampm="false"
                                                                                            autoFocus
                                                                                            InputLabelProps={{ shrink: true, }}
                                                                                            inputProps={{ step: 600 }} //5 minutes
                                                                                            name="dateFrom"
                                                                                            error={touched.dateFrom && Boolean(errors.dateFrom)}
                                                                                            helperText={touched.dateFrom && errors.dateFrom}
                                                                                            variant="outlined"
                                                                                            margin="dense"
                                                                                            onChange={e => { handleDateFChange(e) }}
                                                                                            fullWidth
                                                                                            className={classes.textField}
                                                                                        />
                                                                                </div>
                                                                        
                                                                    
                                                                                <div className="col-md-2">
                                                                                        <Field
                                                                                            as={TextField}
                                                                                            label="Data e Hora Até"
                                                                                            type="datetime-local"
                                                                                            ampm="false"
                                                                                            autoFocus
                                                                                            InputLabelProps={{ shrink: true, }}
                                                                                            inputProps={{ step: 600 }} //5 minutes
                                                                                            name="dateTo"
                                                                                            error={touched.dateTo && Boolean(errors.dateTo)}
                                                                                            helperText={touched.dateTo && errors.dateTo}
                                                                                            variant="outlined"
                                                                                            margin="dense"
                                                                                            onChange={e => { handleDateTChange(e) }}
                                                                                            fullWidth
                                                                                            className={classes.textField}
                                                                                        />
                                                                                </div>

                                                                                <div className="col-md-2">
                                                                                    <button className="btn btn-primary" type="submit" onClick={filtraDashboard} style={{marginTop:10}}>Filtrar</button>
                                                                                </div> 

                                                                                <Can
                                                                                    role={user.profile}
                                                                                    perform="drawer-admin-items:view"
                                                                                    yes={() => (
                                                                                        
                                                                                                <ExportToExcel apiData={data} fileName={fileName} />
                                                                                            
                                                                                        )}
                                                                                />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                        </Form>
                                    )}
                            </Formik>
                        </>
                        <div  style={{height: "100%",overflowY: "hidden",display: "flex",flexDirection: "column"}}>
                            <Grid container alignItems="stretch">
                                
                                <Grid item xs={4} >
                                    <Paper elevation={3} variant="outlined"  style={{margin:10, padding:10, height:330, paddingBottom:50}}>
                                        <Typography component="h3" variant="h6" color="primary" paragraph>
                                                Status dos Atendimentos
                                        </Typography> 
            
                                        <PierChart data={ticketStatus}
                                            title={''}
                                                    colors={['#ff974d', '#fa6f56','#fc5872','#fd4788','#fe3d96','#c71869']}   
                                                />                                   
                                    </Paper>
                                </Grid>
                                
                                <Grid item xs={2}>
                                <Paper elevation={3} variant="outlined"  style={{margin:10, padding:10, height:330}}>
                                    
                                        <div className="col-md-12 col-lg-12">  
                                                        <Typography component="h5" variant="h5" color="primary" style={{ textAlign:"center", fontWeight: 900, fontSize:"2em"}}>
                                                        Contatos Novos
                                                        </Typography>
                                                        <Typography component="div">
                                                            <Box sx={{ textAlign:"center", fontWeight: 900, fontSize:"3.5em", color:"#fe3f93" }}>{(newContacts?newContacts:"0")}</Box>
                                                        </Typography>
                                                        <Typography component="h6" variant="h8"  style={{textAlign:"center", color:"#c71869"}}>
                                                    Contatos Novos são aqueles que chamaram pela primeira vez durante o período
                                                    </Typography>
                                                
                                                </div>

                                                <div className="col-md-12 col-lg-12" style={{marginTop:15}}>  
                                                        <Typography component="h5" variant="h5" color="primary" style={{ textAlign:"center", fontWeight: 900, fontSize:"2em"}}>
                                                        Novos Atendimentos
                                                        </Typography>
                                                        <Typography component="div">
                                                            <Box sx={{ textAlign:"center", fontWeight: 900, fontSize:"3.5em", color:"#fe3f93" }}>{(newTickets?newTickets:"0")}</Box>
                                                        </Typography>
                                                        <Typography component="h6" variant="h8"  style={{textAlign:"center", color:"#c71869"}}>
                                                        Atendimentos Novos são aqueles que foram iniciados no período
                                                    </Typography>
                                                
                                                </div>
                                    
                                </Paper>

                                </Grid>
                                
                                <Grid item xs={6}>
                                    <Paper elevation={3} variant="outlined"  style={{margin:10, padding:10, height:330}}>
                                            <Typography component="h3" variant="h6" color="primary" paragraph>
                                                    Tempo dos Atendimentos
                                            </Typography>
                                            <Grid container alignItems="center">
                                                <Grid item xs={6} >
                                                    <div className="mware-data mware-data-1" >
                                                        <div className="be-booking-desc" style={{flex: "auto"}}>
                                                            <h4 className="be-booking-desc-title">{(firstAnswerMax?firstAnswerMax:"00:00:00")}</h4>
                                                            <span className="be-booking-desc-details">Tempo máximo de primeira resposta</span>
                                                        </div>
                                                    </div>
                                                </Grid>  
                                                <Grid item xs={6} >
                                                    <div className="mware-data mware-data-2" >
                                                        <div className="be-booking-desc" style={{flex: "auto"}}>
                                                            <h4 className="be-booking-desc-title">{(firstAnswerAvg?firstAnswerAvg:"00:00:00")}</h4>
                                                            <span className="be-booking-desc-details">Tempo médio de primeira resposta</span>
                                                        </div>
                                                    </div>
                                            
                                                </Grid>              
                                            </Grid>  
                                            <Grid container alignItems="center">
                                                <Grid item xs={6} >
                                                    <div className="mware-data mware-data-3" >
                                                        <div className="be-booking-desc" style={{flex: "auto"}}>
                                                            <h4 className="be-booking-desc-title">{(closureMax?closureMax:"00:00:00")}</h4>
                                                            <span className="be-booking-desc-details">Tempo máximo de encerramento</span>
                                                        </div>
                                                    </div>
                                                </Grid>  
                                                <Grid item xs={6} >
                                                    <div className="mware-data mware-data-4" >
                                                        <div className="be-booking-desc" style={{flex: "auto"}}>
                                                            <h4 className="be-booking-desc-title">{(closureAvg?closureAvg:"00:00:00")}</h4>
                                                            <span className="be-booking-desc-details">Tempo médio de encerramento</span>
                                                        </div>
                                                    </div>
                                            
                                                </Grid>              
                                            </Grid>  
                                    </Paper>
                                </Grid>

                                <Grid item xs={12}>
                                    <Paper className={classes.fixedHeightPaper} elevation={3} variant="outlined"  style={{margin:10, padding:10}}>
                                        <Chart />
                                    </Paper>
                                </Grid>


                               
                                <Grid container alignItems="stretch">
                                    
                                    <Grid item xs={2} >
                                        <Paper elevation={3} variant="outlined"  style={{margin:10, padding:10}}>
                                            <div className="col-md-12 col-lg-12">  
                                                <Typography component="h5" variant="h5" color="primary" style={{ textAlign:"center", fontWeight: 900, fontSize:"2em"}}>
                                                Em Atendimento, até 7 dias
                                                </Typography>
                                                <Typography component="div">
                                                    <Box sx={{ textAlign:"center", fontWeight: 900, fontSize:"3.5em", color:"#fa6f56" }}>{(openByAge.old07?openByAge.old07:0)}</Box>
                                                </Typography>
                                            </div>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Paper elevation={3} variant="outlined"  style={{margin:10, padding:10}}>
                                            <div className="col-md-12 col-lg-12">  
                                                <Typography component="h5" variant="h5" color="primary" style={{ textAlign:"center", fontWeight: 900, fontSize:"2em"}}>
                                                Em Atendimento, até 15 dias
                                                </Typography>
                                                <Typography component="div">
                                                    <Box sx={{ textAlign:"center", fontWeight: 900, fontSize:"3.5em", color:"#fd4788" }}>{(openByAge.old14?openByAge.old14:0)}</Box>
                                                </Typography>
                                            </div>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Paper elevation={3} variant="outlined"  style={{margin:10, padding:10}}>
                                            <div className="col-md-12 col-lg-12">  
                                                <Typography component="h5" variant="h5" color="primary" style={{ textAlign:"center", fontWeight: 900, fontSize:"2em"}}>
                                                Em Atendimento, mais que 15 dias
                                                </Typography>
                                                <Typography component="div">
                                                    <Box sx={{ textAlign:"center", fontWeight: 900, fontSize:"3.5em", color:"#c71869" }}>{(openByAge.older?openByAge.older:0)}</Box>
                                                </Typography>
                                            </div>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Paper elevation={3} variant="outlined"  style={{margin:10, padding:10}}>
                                            <div className="col-md-12 col-lg-12">  
                                                <Typography component="h5" variant="h5" color="primary" style={{ textAlign:"center", fontWeight: 900, fontSize:"2em"}}>
                                                Aguardando, até 7 dias
                                                </Typography>
                                                <Typography component="div">
                                                    <Box sx={{ textAlign:"center", fontWeight: 900, fontSize:"3.5em", color:"#fa6f56" }}>{(pendingByAge.old07?pendingByAge.old07:0)}</Box>
                                                </Typography>
                                            </div>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Paper elevation={3} variant="outlined"  style={{margin:10, padding:10}}>
                                            <div className="col-md-12 col-lg-12">  
                                                <Typography component="h5" variant="h5" color="primary" style={{ textAlign:"center", fontWeight: 900, fontSize:"2em"}}>
                                                Aguardando, até 15 dias
                                                </Typography>
                                                <Typography component="div">
                                                    <Box sx={{ textAlign:"center", fontWeight: 900, fontSize:"3.5em", color:"#fd4788" }}>{(pendingByAge.old14?pendingByAge.old14:0)}</Box>
                                                </Typography>
                                            </div>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Paper elevation={3} variant="outlined"  style={{margin:10, padding:10}}>
                                            <div className="col-md-12 col-lg-12">  
                                                <Typography component="h5" variant="h5" color="primary" style={{ textAlign:"center", fontWeight: 900, fontSize:"2em"}}>
                                                Aguardando, mais que 15 dias
                                                </Typography>
                                                <Typography component="div">
                                                    <Box sx={{ textAlign:"center", fontWeight: 900, fontSize:"3.5em", color:"#c71869" }}>{(pendingByAge.older?pendingByAge.older:0)}</Box>
                                                </Typography>
                                            </div>
                                        </Paper>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}  justifyContent="space-between">
                                    <Paper className={classes.fixedHeightPaper} elevation={3} variant="outlined"  style={{margin:10, padding:10}}>
                                        <Satisfaction />    
                                    </Paper>
                                </Grid>

                                <Grid item xs={6} >
                                    <Paper className={classes.fixedHeightPaper} elevation={3} variant="outlined"  style={{margin:10, padding:10, height:600}}>
                                        <Typography component="h3" variant="h6" color="primary" paragraph>
                                        Desempenho dos Agentes
                                        </Typography>
                                    
                                        <AgentesDesempenhoTable desempenhos={userTotals}/>
                                    </Paper>
                                </Grid>

                                <Grid item xs={6} >
                                    <Paper className={classes.fixedHeightPaper} elevation={3} variant="outlined"  style={{margin:10, padding:10, height:350}}>
                                        <Typography component="h3" variant="h6" color="primary" paragraph style={{padding:10}}>
                                            {i18n.t("dashboard.messages.totals.queue")}
                                        </Typography>
                                        <FilasTotais filas={queueTotals}/>
                                    </Paper>
                                    <Paper className={classes.fixedHeightPaper} elevation={3} variant="outlined"  style={{margin:10, padding:10, heigth:250}}>
                                        <Typography component="h3" variant="h6" color="primary" paragraph style={{padding:10}}>
                                            {i18n.t("dashboard.messages.totals.tag")}
                                        </Typography>
                                        <TagsTotais tags={tagTotals}/>
                                    </Paper>
                                </Grid>

                            </Grid>
                        </div>
                </Paper>
      
                </>
            
        )
}

export default Tableaux
