import React, { useState, useEffect, useContext, useRef, useReducer } from "react";
import "emoji-mart/css/emoji-mart.css";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import { green } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title"
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { DeleteOutline, Edit, SubdirectoryArrowRightSharp } from "@material-ui/icons";
import SendIcon from "@material-ui/icons/Send";
import { format, parseISO, parse, isValid} from "date-fns";
import { AppBar, ListItemText } from "@material-ui/core";

import { TagsFilterNoPaper } from "../TagsFilterNoPaper"
import { TagsContainerForCampaigns } from "../TagsContainerForCampaigns";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import {
	Select,
	InputLabel,
	FormControl,
	InputAdornment,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	FormControlLabel,
	Hidden,
	Menu,
	MenuItem,
	Switch,
  } from "@material-ui/core";

  import Grid from '@material-ui/core/Grid';

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";


const useStyles = makeStyles((theme) => ({
  popupbox : {
	position: "fixed",
	background: "#00000050",
	width: "100%",
	height: "100vh",
	top: "0",
	left: "0"

  },
  box : {
	position: "relative" ,
	width: "60%" ,
	margin: "0 auto" ,
	height: "auto" ,
	maxHeight: "85vh" ,
	marginTop: "calc(100vh - 85vh - 40px)" ,
	background: "#fff" ,
	borderRadius: "4px" ,
	padding: "20px" ,
	border: "1px solid #999" ,
	overflow: "auto" ,
	zIndex:"1000"
  },
   
  closeicon : {
	content: "x" ,
	cursor: "pointer" ,
	position: "fixed" ,
	right: "calc(22%)" ,
	top: "calc(100vh - 85vh - 33px)" ,
	background: "#ededed" ,
	width: "25px" ,
	height: "25px" ,
	borderRadius: "50%" ,
	lineHeight: "20px" ,
	textAlign: "center" ,
	border: "1px solid #999" ,
	fontSize: "20px" ,
  },

  comment:{
	fontSize: "17px",
	textAlign:"justify",
	lineHeight :"30px"
  },
  sendMessageIcons: {
	color: "grey",
  },
  uploadInput: {
	display: "none",
  },
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
    marginTop: "18px",
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
    marginTop:"12px",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 220,
	},
  smallFormControl: {
		margin: theme.spacing(1),
		minWidth: 160,
	},
  fileLabel:{
    marginTop: "6",
    fontWeight: "700!important",
    fontSize: "20!important"
  },
  selectCtrl: {
    display: "block",
    width: "100%",
    maxHeight: 36,
    fontSize: "13px!important",
    fontFamily: "Roboto",
    fontWeight: 400,
    lineHeight: -1.571429,
    color: "#404040",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid #d5d8de",
    borderRadius: "4px",
    marginTop: "8px!important",
    },
    selectLabel:{
      marginTop: "-0px",
      marginLeft: "15px",
      zIndex: 1,
    },
    fileLabel:{
      marginTop: "12px",
        fontWeight: "900"
    },
    seqField:{
      width:60
    }


  
}));


const reducer = (state, action) => {
  
  if (action.type === "LOAD_CAMPAIGN") {
            const campaigns = action.payload;
            const newcampaigns = [];
            
            campaigns.forEach((campaign) => {
                    const campaignIndex = state.findIndex((c) => c.id === campaign.id);
                    if (campaignIndex !== -1) {
                      state[campaignIndex] = campaign;
                    } else {
                      newcampaigns.push(campaign);
                    }
            });

           
            
            return [...state, ...newcampaigns];
  }

  if (action.type === "LOAD_PLANS") {
    const plans = action.payload;
    const newplans = [];
    
    plans.forEach((plan) => {
      const planIndex = state.findIndex((p) => p.id === plan.id);
      if (planIndex !== -1) {
        state[planIndex] = plan;
      } else {
        newplans.push(plan);
      }
      
    });
   
    return [...state, ...newplans];
  }

  if (action.type === "LOAD_MSGS") {
    const texts = action.payload;
    const newtexts = [];
    
    texts.forEach((text) => {
      const textIndex = state.findIndex((m) => m.id === text.id);
      if (textIndex !== -1) {
        state[textIndex] = text;
      } else {
        newtexts.push(text);
      }
      
    });
   
    return [...state, ...newtexts];
  }


  
}


const CampaignModal = ({open, onClose, initialCampaign})  => {
    const classes = useStyles();

    const [currentPlan, setCurrentPlan] =  useState([]);

    //console.log("MODAL SELECIONADO");
    //console.log(initialCampaign);

    let initialState = null;
    
    if (initialCampaign && initialCampaign?.id) {
      initialState = {
        id:initialCampaign.id,
        name: initialCampaign.name,
        status: initialCampaign.status,
        sendFrom: initialCampaign.sendFrom,  
        image: initialCampaign.image,
        createdAt: initialCampaign.createdAt,
        updatedAt: initialCampaign.updateAt,
        isGroup: initialCampaign.isGroup,
        nameFilter : initialCampaign.nameFilter,
        numberFilter : initialCampaign.numberFilter,
        processingStatus: initialCampaign.processingStatus,
        campaignPlanId: initialCampaign.campaignPlanId,
        templateMessage: initialCampaign.templateMessage,
        flashSend: initialCampaign.flashSend

      };

    
      
    } else {
      initialState = {
        id:0,
        name: "",
        status: 0,
        sendFrom: format(new Date(),'yyyy-MM-dd HH:mm'),  
        image: "",
        createdAt: format(new Date(), "yyyy-MM-dd HH:MM"),
        cupdatedAt: format(new Date(), "yyyy-MM-dd HH:MM"),
        isGroup: 0,
        nameFilter : [],
        numberFilter : "%",
        processingStatus: 0,
        campaignPlanId: 0,
        templateMessage: false,
        flashSend: false
      };
    }

    let buttonState=null;

    buttonState={
      type:2, 
      jid:                      "556584529333",
      jidType:                  "@s.whatsapp.net",
      templateSite1:            "https://mware.com.br",
      templateTextoSite1:       "Acesse nosso site",
      
      templateSite2:            "https://wa.me/55658400000?text=Queria+saber+mais",
      templateTextoSite2:       "Fale com um Consultor",
      
      templateContato:          "+5565984086942",
      templateTextoContato:     "Ligue Agora",
            
      templateDesc:             "A nossa empresa desenvolve ",
      
      templateRodape:           "Clique na opção desejada, para escolher, ou em 'Não Contatar' para não receber mais mensagens nossas (em conformidade com a Lei Geral de Proteção de Dados).",
      
      templateMedia:            "./backend/public/_main/saude.mp4",
      templateTipoMedia:        "video",
      
      templateQuickReply :      "Me Interessa",
      templateBtn1 :            "Não Contatar"
      }







    const [loading, setLoading] = useState(false)
  
    const [displayfn, setDisplayFn] = useState("");
    const [medias, setMedias] = useState([]);
    const [isGroup, setIsGroup] = useState(0);
    const [campaignPlan,  setCampaignPlan] = useState();
    const [inEditMessageId, setinEditMessageId] = useState(0)
    const [inEditMessageFK, setinEditMessageFK] = useState(0)

    const [campaignMsg, setCampaignMsg] = useState(initialState);
   

    const [tagFilter, setTagFilter] = useState([]);
    const [mktPlans, setMktPlans]= useState([]);

    const [campaignData, setCampaignData] = useReducer(reducer, []);
    const [campaignPlans, setCampaignPlans] = useReducer(reducer, []);
    const [campaignMsgs, setCampaignMsgs] = useReducer(reducer, []);
    
    const [campaign, setCampaign] = useState(initialState);
    const [buttonMsg, setButtonMsg] = useState(buttonState);

    const [templateMessage, setTemplateMessage] = useState(0)
    const [flashSend, setFlashSend] = useState(0)
    
    const [tags, setTags] = useState([]);
    const [selecteds, setSelecteds] = useState([]);

  
    useEffect(() => {
      // declare the async data fetching function
      const fetchData = async () => {
        
        setLoading(true);
    
        try { 

          if(initialCampaign && initialCampaign.id){    
              
              const { data } = await api.get("/campaigntexts/"+initialCampaign.id);
              //console.log("initialCampaign && initialCampaign.id - true", data);
              setCampaignMsgs({ type: "LOAD_MSGS", payload: data.campaignTexts });
              setCampaignPlans({ type: "LOAD_PLANS", payload: [data.campaignPlans] });
          } else {
              const { data } = await api.get("/campaignplans");
              //console.log("initialCampaign && initialCampaign.id - false", data);
              setCampaignPlans({ type: "LOAD_PLANS", payload: [data] });
          }
              
          setLoading(false);
            
        }catch(err){
          toastError(err);
          setLoading(false);
        }
    
      }
      const result = fetchData()
        
    }, [])


    useEffect(() => {      
      if (initialCampaign) {
          async function fetchData () {
              //await loadTags();
              if (Array.isArray(initialCampaign.nameFilter)) {
                  setSelecteds(initialCampaign.nameFilter);
              }
          }
          fetchData();
      }
  }, [initialCampaign]);


    const handleClose = () => {
      onClose();
      setCampaign(initialState);
    } 

    const handleSaveCampaign = async (e) => {
        setLoading(true);
        e.preventDefault();
    
    
        const formData = new FormData();
        formData.append("id",campaign.id);
        formData.append("name",campaign.name);
        formData.append("status", campaign.status);
        formData.append("sendFrom",campaign.sendFrom)
        formData.append("createdAt", new Date())
        formData.append("updatedAt", new Date())
        formData.append("isGroup", isGroup);
        formData.append("nameFilter",JSON.stringify(tagFilter));
        formData.append("numberFilter",campaign.numberFilter);
        formData.append("campaignPlanId",campaignPlan);
        formData.append("templateMessage",templateMessage);
        formData.append("flashSend",flashSend);

        if (templateMessage){
          formData.append("buttonMsg",JSON.stringify(buttonMsg));
        }
        
      
    
        medias.forEach((media) => {
          formData.append("medias", media);
        });
    
        try {
       
        for (var pair of formData.entries()) {
          console.log(pair[0]+ ', ' + pair[1]); 
        } 
       
        if(!isValidDate(campaign.sendFrom)){
          toast.error(i18n.t("campaignModal.title.sendfromerror"));
          setLoading(false);
          return;
        }
  
        if(campaign.name ===undefined){
          toast.error(i18n.t("campaignModal.title.nameerror"));
          setLoading(false);
          return;
        }
        if(isGroup===undefined){
          toast.error(i18n.t("campaignModal.title.isgrouperror"));
          setLoading(false);
          return;
        }
        
        if(campaign.nameFilter ===undefined){
          toast.error(i18n.t("campaignModal.title.namefiltererror"));
          setLoading(false);
          return;
        }
        if(campaign.numberFilter===undefined){
          toast.error(i18n.t("campaignModal.title.numberfiltertrerror"));
          setLoading(false);
          return;
        }
        if(campaign.sendFrom ===undefined){
          toast.error(i18n.t("campaignModal.title.sendfromerror"));
          setLoading(false);
          return;
        }
    
        let newCampaign;

        if (campaign.id!=0){
            newCampaign = await api.post(`/campaign/${campaign.id}`, formData);
        }else{
            newCampaign = await api.post(`/campaign`, formData);
        }
        
      
        setinEditMessageFK(newCampaign.data.id);
          
        } catch (err) {
          toastError(err);
        }
    
      toast.success(i18n.t("campaignModal.title.addsuccess"));
        setLoading(false);
        setMedias([]);

    } 

    const handleSaveCampaignMessage = async values => {

      let saveData=values;
      let ret;
      saveData.messageFK=campaign.id;

      if(campaign.id==0){
        return;
      }

      setLoading(true);

      console.log("saveData", saveData)

      try {
        if (saveData.messageId!=undefined) {
              ret  = await api.put(`/campaigntexts/${saveData.messageId}`, saveData);
        } else {
              ret  = await api.post("/campaigntexts", saveData);
        }

        console.log("ret.data.campaignTexts", ret.data.campaignTexts)

        setCampaignData(ret.data.campaignData);
        setCampaignMsgs({ type: "LOAD_MSGS", payload: ret.data.campaignTexts });
        setinEditMessageFK(ret.data.campaignData.id)

        setLoading(false);


        toast.success(i18n.t("campaignTextModal.success"));

      } catch (err) {
        toastError(err);
      }

    };

    const pullMsg = (msgtext) =>{;
    setLoading(true);

    let editState = {
      messageId: "",
      messageText: "",	
      messageFK: ""
    };

    editState.messageId=msgtext.id;
    editState.messageText = msgtext.text;
    editState.messageFK=msgtext.campaignId;

    setinEditMessageId(msgtext.id);
    setinEditMessageFK(msgtext.campaignId);
    setCampaignMsg(editState);

    setLoading(false);

    }

    const delMsg = async (msgtext) =>{;

    setLoading(true);

    let editState = {
      messageId: "",
      messageText: "",	
      messageFK: ""
    };

    editState.messageId=msgtext.id;
    editState.messageText = msgtext.text;
    editState.messageFK=msgtext.campaignId;

    try {
      const ret  = await api.delete(`/campaigntexts/${msgtext.id}`, editState);
      console.log("ret.data.campaignTexts", ret.data.campaignTexts)

      //setCampaignData(ret.data.campaignData);
      setCampaignMsgs({ type: "LOAD_MSGS", payload: [] });
      
      setCampaignMsgs({ type: "LOAD_MSGS", payload: ret.data.campaignTexts });
      //setinEditMessageFK(ret.data.campaignData.id)

      setLoading(false);


      toast.success(i18n.t("campaignTextModal.deleted"));

    } catch (err) {
      toastError(err);
    }
    }

    const handleChangeCampaignPlan = (e) => {
        console.log(e.target.value);
        campaign.campaignPlanId = e.target.value;
        setCurrentPlan(e.target.value)
        setCampaignPlan(e.target.value)
    }

    const handleChangeSegment = (e) => {
      
      setIsGroup(e.target.value)
      setCampaign({ ...campaign, isGroup: e.target.value }) 
    }

    const handleChangeInput = (e) => {
      setCampaign({ ...campaign, [e.target.name]: e.target.value })  
    };

    const handleFilterTag = (e) => { 
      console.log("handleFilterTag", e)
      //const tagarray=e.map(e => e.id)
      //setTagFilter(tagarray)
      setTagFilter(e)

    };

    const handleUpdateCampaign = () => {
      //onClose();
      //setCampaign(initialState);
    } 

    const handleChangeMedias = (e) => {
      if (!e.target.files) {
        return;
      }
      const selectedMedias = Array.from(e.target.files);
      
      setMedias(selectedMedias);
      setDisplayFn(medias[0]?.name);
  
    };

    const handleChangeButtonInput = (e) => {
      setButtonMsg({ ...buttonMsg, [e.target.name]: e.target.value })  
    };


    const sendTestMessage = async () => {

      try {
              let ret  = await api.post(`api/messages/sendplusplus`,buttonMsg);
              console.log(ret)
        }catch(err){
              console.log(err)
        }


    }





    function isValidDate(dateObject){
      return new Date(dateObject).toString() !== 'Invalid Date';
  }


    return (
        <div className={classes.popupbox}>
            <div className={classes.box}>
                <span className={classes.closeicon} onClick={handleClose} >x</span>
                <MainContainer>
    
                        <MainHeader>
                            <Title>{i18n.t("campaignModal.title.mainTitle")}</Title>
                        </MainHeader>

                        <DialogContent dividers style={{ overflow: "hidden" }}>

                            <Formik
                              initialValues={campaign}
                              enableReinitialize={true}
                          
                              onSubmit={(values, actions) => {
                                setTimeout(() => {
                                    //handleSaveWhatsApp(values);
                                    //actions.setSubmitting(false);
                                }, 400);
                              }}
                            >
                              <Form>
              
                                  <div>
                                      <FormControl fullWidth>
                                          <InputLabel id="campaignPlanId-label" className={classes.selectLabel}> {i18n.t("campaignPlan.modal.planName")}</InputLabel>
                                          <Field className={classes.selectCtrl}
                                              as={Select}
                                              autoFocus
                                              labelId="campaignPlanId-label"
                                              id="campaignPlanId"
                                              label={i18n.t("campaignPlan.modal.planName")}
                                              placeholder={i18n.t("campaignPlan.modal.planName")}
                                              variant="outlined"
                                              margin="dense"
                                              name="campaignPlanId"
                                              fullWidth
                                              onChange={(e) => handleChangeCampaignPlan(e)}
                                              //value={campaign.campaignPlanId}
                                            >
                                              {campaignPlans[0]?.length > 0 && campaignPlans[0].map((plan) => (
                                                  <MenuItem value={plan.id}>{plan.planName}</MenuItem>
                                              ))}
                                              
                                          </Field>


                                      </FormControl>
                                  </div>
                                  <div className={classes.multFieldLine}>  
                                      <Field
                                        as={TextField}
                                        label={i18n.t("campaignModal.form.name")}
                                        name="name"
                                        id="name"
                                        value={campaign.name}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.textField}
                                        onChange={(e) => handleChangeInput(e)}
                                        fullWidth
                                      />

                                      <FormControlLabel
                                          style={{ marginRight: 7, color: "gray", marginTop: 17 }}
                                          label={'Flash Send'}
                                          labelPlacement="start"
                                          control={
                                            <Switch
                                              size="small"
                                              checked={flashSend}
                                              onChange={(e) => {
                                                setFlashSend(e.target.checked);
                                              }}
                                              name="flashSend"
                                              color="primary"
                                            />
                                          }
                                        />
                                      
                                        <FormControlLabel
                                          style={{ marginRight: 7, color: "gray", marginTop: 17 }}
                                          label={'Template Avançado'}
                                          labelPlacement="start"
                                          control={
                                            <Switch
                                              size="small"
                                              checked={templateMessage}
                                              onChange={(e) => {
                                                setTemplateMessage(e.target.checked);
                                              }}
                                              name="templateMessage"
                                              color="primary"
                                            />
                                          }
                                        />
                                    
                                      
                                  </div>

                                  <div className={classes.multFieldLine}>           
                                      <FormControl fullWidth>
                                          <InputLabel id="demo-simple-select-label" className={classes.selectLabel}> {i18n.t("campaignModal.form.segment")}</InputLabel>
                                          <Select className={classes.selectCtrl}
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              value={campaign.isGroup}
                                              label={i18n.t("campaignModal.form.segment")}
                                              variant="outlined"
                                              margin="dense"
                                              fullWidth
                                              onChange={(e) => handleChangeSegment(e)}
                                              >
                                                  <MenuItem value={0}>{i18n.t("campaignModal.form.contacts")}</MenuItem>
                                                  <MenuItem value={3}>{i18n.t("campaignModal.form.geoselection")}</MenuItem>
                                                 
                                          </Select>
                                      </FormControl>
                                  
                                      <Field
                                      as={TextField} 
                                      fullWidth
                                      name="numberFilter"
                                      id="numberFilter"
                                      value={campaign.numberFilter}
                                  
                                      label={i18n.t("campaignModal.form.channels")}
                                      variant="outlined"
                                      margin="dense"
                                      onChange={(e) => handleChangeInput(e)}
                                      />

                                      <Field
                                      as={TextField}
                                      label={i18n.t("campaignModal.form.sendFrom")}
                                      fullWidth
                                      name="sendFrom"
                                      id="sendFrom"
                                      value={campaign.sendFrom} 
                                      variant="outlined"
                                      margin="dense"
                                      onChange={(e) => handleChangeInput(e)}          
                                      />
                                  </div>


                                <TagsContainerForCampaigns 
                                      campaign={campaign} 
                                      onFiltered={(e) => handleFilterTag(e)}
                                      onChange={(e) => handleFilterTag(e)}

                                      />

                                  <div className={classes.multFieldLine}>
                                      <input
                                          multiple
                                          type="file"
                                          id="upload-button"
                                          className={classes.uploadInput}
                                          onChange={handleChangeMedias} 
                                      />
                                      <label htmlFor="upload-button">
                                      Mídia(s) para Campanha:&nbsp;
                                      <IconButton
                                          aria-label="upload"
                                          component="span"
                                      >
                                          <AttachFileIcon className={classes.sendMessageIcons} />
                                      
                                      </IconButton>
                                      </label>	
                                      <label htmlFor="upload-button" fullWidth className={classes.fileLabel}>
                                          {medias?.length>0 ?medias[0]?.name : campaign.image}
                                      </label>
                                  </div>

                                  {templateMessage==1 && 
                                  
                                      <Grid item xs={12}>
                                        <Typography variant="h5" display="block" gutterBottom>
                                            Configuração de Botões
                                        </Typography>
                                          <div className={classes.multFieldLine} style={{marginTop:"-10px"}}>     
                                          <Typography display="block"style={{marginRight:"21px", marginTop:"27px", width: "100px"}}>
                                                Botão URL 1
                                            </Typography>      
                                            <Field className={classes.textField}
                                                onChange={(e) => handleChangeButtonInput(e)}
                                                as={TextField} 
                                                fullWidth
                                                name="templateSite1"
                                                id="templateSite1"
                                                label={i18n.t("campaignButtonModal.url1")}
                                                placeholder={i18n.t("campaignButtonModal.url1Placeholder")}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                            <Field className={classes.textField}
                                                onChange={(e) => handleChangeButtonInput(e)}
                                                as={TextField} 
                                                fullWidth
                                                name="templateTextoSite1"
                                                id="templateTextoSite1"  
                                                label={i18n.t("campaignButtonModal.url1Caption")}
                                                placeholder={i18n.t("campaignButtonModal.url1CaptionPlaceholder")}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                          </div>  


                                          <div className={classes.multFieldLine} style={{marginTop:"-10px"}}>     
                                          <Typography display="block"style={{marginRight:"21px", marginTop:"27px", width: "100px"}}>
                                                Botão URL 2
                                            </Typography>      
                                            <Field className={classes.textField}
                                                onChange={(e) => handleChangeButtonInput(e)}
                                                as={TextField} 
                                                fullWidth
                                                name="templateSite2"
                                                id="templateSite2"
                                                label={i18n.t("campaignButtonModal.url2")}
                                                placeholder={i18n.t("campaignButtonModal.url2Placeholder")}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                            <Field className={classes.textField}
                                                onChange={(e) => handleChangeButtonInput(e)}
                                                as={TextField} 
                                                fullWidth
                                                name="templateTextoSite2"
                                                id="templateTextoSite2"  
                                                label={i18n.t("campaignButtonModal.url2Caption")}
                                                placeholder={i18n.t("campaignButtonModal.url2CaptionPlaceholder")}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                          </div> 

                                          <div className={classes.multFieldLine} style={{marginTop:"-10px"}}>     
                                          <Typography display="block"style={{marginRight:"21px", marginTop:"27px", width: "100px"}}>
                                                Botão Telefone
                                            </Typography>      
                                            <Field className={classes.textField}
                                                onChange={(e) => handleChangeButtonInput(e)}
                                                as={TextField} 
                                                fullWidth
                                                name="templateContato"
                                                id="templateContato"
                                                label={i18n.t("campaignButtonModal.phone1")}
                                                placeholder={i18n.t("campaignButtonModal.phone1Placeholder")}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                            <Field className={classes.textField}
                                                onChange={(e) => handleChangeButtonInput(e)}
                                                as={TextField} 
                                                fullWidth
                                                name="templateTextoContato"
                                                id="templateTextoContato"  
                                                label={i18n.t("campaignButtonModal.phone1Caption")}
                                                placeholder={i18n.t("campaignButtonModal.phone1CaptionPlaceholder")}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                          </div> 


                                          <div className={classes.multFieldLine} style={{marginTop:"-10px"}}>     
                                           <Typography display="block"style={{marginRight:"21px", marginTop:"27px", width: "100px"}}>
                                                Mensagem
                                            </Typography>      
                                            <Field className={classes.textField}
                                                onChange={(e) => handleChangeButtonInput(e)}
                                                as={TextField} 
                                                fullWidth
                                                name="templateDesc"
                                                id="templateDesc"
                                                multiline
                                                rows={4}
                                                label={i18n.t("campaignButtonModal.mensagem")}
                                                placeholder={i18n.t("campaignButtonModal.mensagemPlaceholder")}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                           
                                          </div> 
                                          <div className={classes.multFieldLine} style={{marginTop:"-10px"}}>     
                                           <Typography display="block" style={{marginRight:"21px", marginTop:"27px", width: "100px"}}>
                                                Faça um Teste
                                            </Typography>      
                                            <Field className={classes.textField}
                                                onChange={(e) => handleChangeButtonInput(e)}
                                                as={TextField} 
                                                fullWidth
                                                name="jid"
                                                id="jid"
                                                label={i18n.t("campaignButtonModal.jid")}
                                                placeholder={i18n.t("campaignButtonModal.jidPlaceholder")}
                                                variant="outlined"
                                                margin="dense"
                                              
                                            />
                                            <DialogActions>
                                              <Button
                                                    onClick={sendTestMessage}
                                                    color="secondary"
                                                    variant="outlined"
                                                    className={classes.btnWrapper}
                                                    style={{marginTop:"13px"}}
                                                  >
                                                    {i18n.t("campaignButtonModal.sendTest")}
                                              </Button>
                                            </DialogActions>
                                            
                                           
                                          </div> 



                                          
                                      </Grid>
                                  }






                              </Form>
                          </Formik>
                        </DialogContent>

                        <DialogActions>
                            <Button
                              onClick={handleClose}
                              color="secondary"
                              disabled={loading}
                              variant="outlined"
                            >
                              {i18n.t("whatsappModal.buttons.cancel")}
                            </Button>
                            <Button
                              type="submit"
                              color="primary"
                              disabled={loading}
                              variant="contained"
                              onClick={handleSaveCampaign}
                              className={classes.btnWrapper}
                            >
                                  {initialState.id==0
                                        ? i18n.t("whatsappModal.buttons.okAdd")
                                        : i18n.t("whatsappModal.buttons.okEdit")
                                      }
                              
                            </Button>
                        </DialogActions>

                        {templateMessage==0 && <Grid item xs={12}>
                          <MainHeader>
                            <Title>{i18n.t("campaignTextModal.title")}:</Title>
                          </MainHeader>

                          <DialogContent dividers>
                                  
                                    
                                          <Table size="small">
                                              <TableHead>
                                              <TableRow>
                                                  <TableCell align="center">{i18n.t("campaignTextModal.table.sequence")}</TableCell>
                                                  <TableCell align="center">{i18n.t("campaignTextModal.table.text")}</TableCell>
                                                  <TableCell align="center">{i18n.t("campaignTextModal.table.actions")}</TableCell>
                                              </TableRow>
                                              </TableHead>	

                                              <TableBody>
                                              <>
                                              
                                              {campaignMsgs?.length > 0 && campaignMsgs.map((msgtext) => (

                                                  <TableRow key={msgtext.id==undefined?0:msgtext.id}>
                                                  <TableCell align="center" style={{width:"5%"}}>{msgtext.sequence}</TableCell>
                                                  <TableCell align="center" style={{width:"85%"}}>{msgtext.text}</TableCell>
                                                  
                                                  <TableCell align="center">
                                                      <IconButton
                                                      size="small"
                                                      onClick={(e) => {
                                                      pullMsg(msgtext)
                                                      }}
                                                      >
                                                      <Edit />
                                                      </IconButton>

                                                      <IconButton
                                                      size="small"
                                                      onClick={(e) => {
                                                      delMsg(msgtext)
                                                      }}
                                                      >
                                                      <DeleteOutline />
                                                      </IconButton>
                                                  </TableCell>
                                                  </TableRow>
                                              ))}
                                              {loading && <TableRowSkeleton columns={2} />}
                                              </>
                                              </TableBody>
                                          </Table>
                          </DialogContent>    

                          <Formik
                            initialValues={campaignMsg}
                            enableReinitialize={true}
                            onSubmit={(values, actions) => {
                              
                              setTimeout(() => {
                                handleSaveCampaignMessage(values);
                                actions.setSubmitting(false);
                              }, 400);
                              
                            }}
                          >
                            {({ values, isSubmitting }) => (
                            <Form>
                              <DialogContent dividers>
                            
                              <div className={classes.multFieldLine}>                                                
                                  <Field className={classes.seqField}
                                  as={TextField} 
                                  fullWidth
                                  name="messageSeq"
                                  id="messageSeq"
                                  //value={campaign.nameFilter}
                              
                                  label="Seq."
                                  variant="outlined"
                                  margin="dense"
                                  //onChange={(e) => handleChangeInput(e)}
                                  />
                                  <Field
                                    as={TextField}
                                    label={i18n.t("campaignTextModal.messagebox")}
                                    type="messageText"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    name="messageText"
                                    
                                    variant="outlined"
                                    margin="dense"
                                  />
                                  </div>
                                
                              </DialogContent>
                              <DialogActions>
                                
                                <Button
                                  type="submit"
                                  color="primary"
                                  enabled={campaignMsg.messageFK!==0}
                                  variant="contained"
                                  className={classes.btnWrapper}
                                >
                                  
                                  {inEditMessageId!=0
                                    ? i18n.t("whatsappModal.buttons.okEdit")
                                    : i18n.t("whatsappModal.buttons.okAdd")}
                                
                            
                                </Button>
                              </DialogActions>
                            </Form>
                            )}
                          </Formik>
                        </Grid>}
                                  
                </MainContainer>


            </div>
        </div>

)};

export default CampaignModal;
